<template>
    <div>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.shiftStarts.$errors.length }"
                    :label="$t('forms.shiftStarts')"
                    label-for="shiftStarts"
                    label-cols-md="4"
                    content-cols-lg="4"
                >
                    <datepicker
                        id="shiftStarts"
                        v-model="shiftStarts"
                        :maxDate="new Date()"
                        class="form-input"
                        :name="$t('forms.shiftStarts')"
                        format="dd.MM.yyyy HH:mm"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.shiftStarts.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.shiftEnds.$errors.length }"
                    :label="$t('forms.shiftEnds')"
                    label-for="shiftEnds"
                    label-cols-md="2"
                    content-cols-lg="4"
                >
                    <datepicker
                        id="shiftEnds"
                        v-model="shiftEnds"
                        :maxDate="new Date()"
                        :maxTime="{ hours: (new Date()).getHours(), minutes: (new Date()).getMinutes() }"
                        class="form-input"
                        :name="$t('forms.shiftEnds')"
                        format="dd.MM.yyyy HH:mm"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.shiftEnds.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.acDelayed.$errors.length }"
                    :label="$t('forms.acDelayed')"
                    label-for="acDelayed"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="acDelayed"
                        :name="$t('forms.acDelayed')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="acDelayed"
                        :name="$t('forms.acDelayed')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.acDelayed.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.communicationMcc.$errors.length }"
                    :label="$t('forms.communicationMcc')"
                    label-for="communicationMcc"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="communicationMcc"
                        :name="$t('forms.communicationMcc')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="communicationMcc"
                        :name="$t('forms.communicationMcc')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.communicationMcc.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.wetEngine.$errors.length }"
                    :label="$t('forms.wetEngine')"
                    label-for="wetEngine"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="wetEngine"
                        :name="$t('forms.wetEngine')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="wetEngine"
                        :name="$t('forms.communicationMcc')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.wetEngine.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.stocksUpdated.$errors.length }"
                    :label="$t('forms.stocksUpdated')"
                    label-for="stocksUpdated"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="stocksUpdated"
                        :name="$t('forms.stocksUpdated')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="stocksUpdated"
                        :name="$t('forms.stocksUpdated')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.stocksUpdated.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.toolingChecked.$errors.length }"
                    :label="$t('forms.toolingChecked')"
                    label-for="toolingChecked"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="toolingChecked"
                        :name="$t('forms.toolingChecked')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="toolingChecked"
                        :name="$t('forms.toolingChecked')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.toolingChecked.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.toolboxChecked.$errors.length }"
                    :label="$t('forms.toolboxChecked')"
                    label-for="toolboxChecked"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="toolboxChecked"
                        :name="$t('forms.toolboxChecked')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="toolboxChecked"
                        :name="$t('forms.toolboxChecked')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.toolboxChecked.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.toolingServicable.$errors.length }"
                    :label="$t('forms.toolingServicable')"
                    label-for="toolingServicable"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="toolingServicable"
                        :name="$t('forms.toolingServicable')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="toolingServicable"
                        :name="$t('forms.toolingServicable')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.toolingServicable.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.woOpen.$errors.length }"
                    :label="$t('forms.woOpen')"
                    label-for="woOpen"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio v-model="woOpen" :name="$t('forms.woOpen')" value="Yes">
                        Yes
                    </b-form-radio>
                    <b-form-radio v-model="woOpen" :name="$t('forms.woOpen')" value="No">
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.woOpen.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                    <div class="small">If you chose YES, please enter WO number</div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.closeWo.$errors.length }"
                    :label="$t('forms.closeWo')"
                    label-for="closeWo"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="closeWo"
                        :name="$t('forms.closeWo')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="closeWo"
                        :name="$t('forms.closeWo')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.closeWo.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input placeholder="If special requirement please enter them" :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.openingAc.$errors.length }"
                    :label="$t('forms.openingAc')"
                    label-for="openingAc"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="openingAc"
                        :name="$t('forms.openingAc')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="openingAc"
                        :name="$t('forms.openingAc')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.openingAc.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input placeholder="If special requirement please enter them" :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.handlingWizz.$errors.length }"
                    :label="$t('forms.handlingWizz')"
                    label-for="handlingWizz"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="handlingWizz"
                        :name="$t('forms.handlingWizz')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="handlingWizz"
                        :name="$t('forms.handlingWizz')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.handlingWizz.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input placeholder="If special requirement please enter them" :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.n2Quantity.$errors.length }"
                    :label="$t('forms.n2Quantity')"
                    label-for="n2Quantity"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="n2Quantity"
                        :name="$t('forms.n2Quantity')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="n2Quantity"
                        :name="$t('forms.n2Quantity')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.n2Quantity.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.companyCarOk.$errors.length }"
                    :label="$t('forms.companyCarOk')"
                    label-for="companyCarOk"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="companyCarOk"
                        :name="$t('forms.companyCarOk')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="companyCarOk"
                        :name="$t('forms.companyCarOk')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.companyCarOk.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.officeStock.$errors.length }"
                    :label="$t('forms.officeStock')"
                    label-for="officeStock"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="officeStock"
                        :name="$t('forms.officeStock')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="officeStock"
                        :name="$t('forms.officeStock')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.officeStock.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.devicesProblem.$errors.length }"
                    :label="$t('forms.devicesProblem')"
                    label-for="devicesProblem"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="devicesProblem"
                        :name="$t('forms.devicesProblem')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="devicesProblem"
                        :name="$t('forms.devicesProblem')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.devicesProblem.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.stockTemperature.$errors.length }"
                    :label="$t('forms.stockTemperature')"
                    label-for="stockTemperature"
                    label-cols-md="2"
                    content-cols-lg="4"
                >
                    <b-form-input
                        v-model="stockTemperature"
                        :name="$t('forms.stockTemperature')"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.stockTemperature.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-form-group
            :class="{ row, error: v$.stockHumidity.$errors.length }"
            :label="$t('forms.stockHumidity')"
            label-for="stockHumidity"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="stockHumidity" :name="$t('forms.stockHumidity')"/>
            <div
                class="input-errors"
                v-for="error of v$.stockHumidity.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <hr/>
        <table class="table table-bordered table-cwo">
            <thead>
            <tr>
                <th>Tail number</th>
                <th>Time of GPU usage and APU usage in minutes</th>
                <th>Enter reason if APU was in use</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <b-form-input :name="$t('forms.tallNumber')"/>
                </td>
                <td>
                    <b-row>
                        <b-col>
                            <b-form-input placeholder="GPU" :name="$t('forms.gpuUsage')"/>
                        </b-col>
                        <b-col>
                            <b-form-input placeholder="APU" :name="$t('forms.apuUsage')"/>
                        </b-col>
                    </b-row>
                </td>
                <td>
                    <b-form-input :name="$t('forms.apuReason')"/>
                </td>
            </tr>
            <tr>
                <td>
                    <b-form-input :name="$t('forms.tallNumber')"/>
                </td>
                <td>
                    <b-row>
                        <b-col>
                            <b-form-input placeholder="GPU" :name="$t('forms.gpuUsage')"/>
                        </b-col>
                        <b-col>
                            <b-form-input placeholder="APU" :name="$t('forms.apuUsage')"/>
                        </b-col>
                    </b-row>
                </td>
                <td>
                    <b-form-input :name="$t('forms.apuReason')"/>
                </td>
            </tr>
            <tr>
                <td>
                    <b-form-input :name="$t('forms.tallNumber')"/>
                </td>
                <td>
                    <b-row>
                        <b-col>
                            <b-form-input placeholder="GPU" :name="$t('forms.gpuUsage')"/>
                        </b-col>
                        <b-col>
                            <b-form-input placeholder="APU" :name="$t('forms.apuUsage')"/>
                        </b-col>
                    </b-row>
                </td>
                <td>
                    <b-form-input :name="$t('forms.apuReason')"/>
                </td>
            </tr>
            <tr>
                <td>
                    <b-form-input :name="$t('forms.tallNumber')"/>
                </td>
                <td>
                    <b-row>
                        <b-col>
                            <b-form-input placeholder="GPU" :name="$t('forms.gpuUsage')"/>
                        </b-col>
                        <b-col>
                            <b-form-input placeholder="APU" :name="$t('forms.apuUsage')"/>
                        </b-col>
                    </b-row>
                </td>
                <td>
                    <b-form-input :name="$t('forms.apuReason')"/>
                </td>
            </tr>
            </tbody>
        </table>
        <b-row>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.issueToFollow')"
                    label-for="issueToFollow"
                    label-cols-md="2"
                    content-cols-lg="4"
                >
                    <b-form-input
                        v-model="issueToFollow"
                        :name="$t('forms.issueToFollow')"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.disappointed')"
                    label-for="disappointed"
                    label-cols-md="2"
                    content-cols-lg="4"
                >
                    <b-form-input
                        :name="$t('forms.disappointed')"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <div class="small">
            The answer will be sent to MGT only, it will not be displayed in the submitted form.
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
    components: {
        Datepicker,
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            shiftStarts: {required},
            shiftEnds: {required},
            acDelayed: {required},
            communicationMcc: {required},
            wetEngine: {required},
            stocksUpdated: {required},
            toolingChecked: {required},
            toolboxChecked: {required},
            toolingServicable: {required},
            woOpen: {required},
            closeWo: {required},
            openingAc: {required},
            handlingWizz: {required},
            n2Quantity: {required},
            companyCarOk: {required},
            officeStock: {required},
            devicesProblem: {required},
            stockTemperature: {required},
            stockHumidity: {required}
        };
    },
    data() {
        return {
            shiftStarts: null,
            shiftEnds: null,
            acDelayed: null,
            communicationMcc: null,
            wetEngine: null,
            stocksUpdated: null,
            toolingChecked: null,
            toolboxChecked: null,
            toolingServicable: null,
            woOpen: null,
            closeWo: null,
            openingAc: null,
            handlingWizz: null,
            n2Quantity: null,
            companyCarOk: null,
            officeStock: null,
            devicesProblem: null,
            stockTemperature: null,
            stockHumidity: null,
            issueToFollow: null
        };
    },
};
</script>

<style>
</style>
