<template>
    <div class="add-new-form">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.formCreate')"
            :title="title"
            @submitPressed="submitPressed"
        />
        <create-form :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CreateForm from "./components/CreateForm.vue";

export default {
    components: {
        TitleBar,
        CreateForm,
    },
    data() {
        return {
            action: null,
            title: null
        };
    },
    created() {
        const id = this.$route.params.id;
        this.$FormTypes.getResource({id}).then((response) => {
            this.title = response.data.name;
        });
    },
    methods: {
        submitPressed() {
            this.action = "add";
        },
    },
};
</script>
